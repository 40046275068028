/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
// stage

const awsmobile = {
    aws_project_region: "us-west-2",
    aws_appsync_graphqlEndpoint: "https://6ahje3wl4rh2tjnftfrhgg4fvu.appsync-api.us-west-2.amazonaws.com/graphql",
    aws_appsync_region: "us-west-2",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    aws_cognito_region: "us-west-2",
    aws_user_pools_id: "us-west-2_eDNxzdQWH",
    aws_user_pools_web_client_id: "5oah9snivmcm4ppus433hpod3d",
    aws_cognito_identity_pool_id: "us-west-2:43317575-3738-42b2-a816-3cce25b6f5ad",
    Auth: {
        region: "us-west-2",
        userPoolId: "us-west-2_eDNxzdQWH",
        userPoolWebClientId: "5oah9snivmcm4ppus433hpod3d",
        identityPoolId: "us-west-2:43317575-3738-42b2-a816-3cce25b6f5ad",
    },
    mandatorySignIn: true,
    oauth: {
        domain: "prod.auth.reliancelitigation.com",
        scope: ["email", "profile", "openid"],
        redirectSignIn: "https://surveybuilder.reliancelitigation.com/",
        redirectSignOut: "https://surveybuilder.reliancelitigation.com/",
        responseType: "code",
    },
};

export default awsmobile;
